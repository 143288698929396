<template>
  <div class="mt-4">
    <h2
      v-if="title"
      class="mb-4"
    >
      {{ title }}
    </h2>

    <v-row>
      <v-col>
        <v-combobox
          label="Марка"
          v-model="meter.brand"
          :items="meter_brands.filter(m => m.type_id === 1).map(brand => brand.name)"
        />
      </v-col>

      <v-col>
        <v-select
          label="Типоразмер"
          v-model="meter.meter_id"
          :items="meters.filter(m => m.type_id === 1)"
          item-value="id"
          item-text="name"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          label="Заводской номер"
          v-model="meter.factory_number"
        />
      </v-col>

      <v-col>
        <v-text-field
          type="number"
          label="Год выпуска"
          v-model="meter.issue_date"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-text-field
          type="number"
          label="Показания счетчика"
          v-model="meter.value"
        />
      </v-col>

      <v-col>
        <v-select
          :items="install_places.map(item => ({ value: item.id, text: item.name }))"
          label="Место установки"
          v-model="meter.install_place_id"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-select
          :items="damage_types.map(item => ({ value: item.id, text: item.name }))"
          label="Наличие/отсутствие механических повреждений и вмешательств"
          v-model="meter.damage_type_id"
        />
      </v-col>
    </v-row>

    <v-row v-if="mode === 'verify'">
      <v-col cols="12" md="6">
        <v-select
          :items="fit_statuses.map(item => ({ value: item.id, text: item.name }))"
          label="Признан годным/негодным"
          v-model="meter.fit_status_id"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-select
          :items="seal_presences.map(item => ({ value: item.id, text: item.name }))"
          label="Пломба на месте присоединения в сохранном состоянии"
          v-model="meter.seal_presence_id"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          label="Номер пломбы (снятие)"
          v-model="meter.seal_number_removal"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-text-field
          label="Номер пломбы (установка)"
          v-model="meter.seal_number_install"
        />
      </v-col>
    </v-row>

    <slot name="verification" />
  </div>
</template>

<script>
import { ListsService } from '@/common/api.service'

export default {
  props: {
    value: {
      type: Object
    },
    title: {
      type: String
    },
    mode: {
      type: String,
      default: 'verify'
    }
  },

  data () {
    return {
      install_places: [],
      damage_types: [],
      seal_presences: [],
      fit_statuses: [],
      meters: [],
      meter_brands: []
    }
  },

  created () {
    ListsService
        .get(['install_places', 'damage_types', 'seal_presences', 'fit_statuses', 'meters', 'meter_brands'])
        .then(({ data }) => {
          this.install_places = data.install_places
          this.damage_types = data.damage_types
          this.seal_presences = data.seal_presences
          this.fit_statuses = data.fit_statuses
          this.meters = data.meters
          this.meter_brands = data.meter_brands
        })
  },

  computed: {
    meter: {
      get () {
        return this.value || {}
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
