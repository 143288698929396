<script>
export default {
  props: {
    value: {
      required: true
    },
    errorMessages: {
      type: Array,
      required: false
    },
    regionCode: {
      required: true
    },
    disabled: {
      type: Boolean,
      required: false
    }
  },

  data () {
    return {
      loading: false,
      items: [],
      select: null,
      timeout: null,

      isInputFocused: false
    }
  },

  computed: {
    address: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },

  methods: {
    // Правила валидации
    rules () {
      return  !!this.value || 'Выберите адрес'
    },

    apiCall () {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      if (!this.address) {
        return
      }

      const params = { query: this.address, locations: { kladr_id: this.regionCode, count: 1 } }
      const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address'
      const token = '3eb7dd29ce03e3471c88e61c589dc29660c2b074'
      const options = {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Token ${token}`
        },
        body: JSON.stringify(params)
      }

      this.timeout = setTimeout(() => {
        fetch(url, options)
          .then(response => response.json())
          .then((result) => {
            this.items = result.suggestions
          })
          .catch(error => console.log('error', error))
      }, 1000)
    },

    // Выбран адрес из списка
    addressSelected (value) {
      this.$emit('addressSelected', this.items.find(item => item.unrestricted_value === value.unrestricted_value))
    }
  },

  watch: {
    address: {
      handler () {
        if (!this.isInputFocused) {
          return
        }

        this.apiCall()
      }
    }
  }
}
</script>

<template>
  <v-combobox
    :loading="loading"
    :items="items"
    :search-input.sync="address"
    :rules="[rules]"
    :error-messages="errorMessages"
    item-value="unrestricted_value"
    item-text="unrestricted_value"
    no-filter
    hide-no-data
    hide-selected
    :disabled="disabled"
    @focus="isInputFocused = true"
    @change="addressSelected"
  >
    <template #label>
      Адрес<span class="error--text">*</span>
    </template>
  </v-combobox>
</template>
