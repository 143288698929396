<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="400px"
  >
    <template #activator="{ on, attrs }">
      <v-btn
        color="primary"
        v-bind="attrs"
        v-on="on"
        :disabled="disabled"
      >
        Добавить в маршрут
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <span class="headline">
          Добавление в маршрут
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
            >
              <v-select
                v-if="!selectedRoute"
                :items="regions"
                label="Регион"
                :value="regionId"
                item-value="id"
                item-text="name"
                @change="route.verifier_id = null"
                disabled
              />

              <Datepicker
                title="Дата поверки"
                clearable
                v-model="route.date"
                :is-new="true"
                @clear="route.date = null"
              />

              <v-select
                v-if="routes"
                label="Маршрут"
                clearable
                v-model="selectedRoute"
                :items="allowedRoutes"
                item-text="name"
                return-object
              />

              <v-text-field
                v-if="!selectedRoute"
                label="Название"
                v-model="route.name"
                :disabled="!route.region_id"
              />

              <v-select
                v-if="!selectedRoute"
                :items="allowedVerifiers"
                label="Поверитель"
                v-model="route.verifier_id"
                :disabled="!route.region_id"
                clearable
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          color="blue darken-1"
          text
          @click="dialog = false"
        >
          Отмена
        </v-btn>

        <v-btn
          outlined
          color="blue"
          :loading="routeSaving"
          @click="onSubmit"
          :disabled="routeSaving || ((!route.name || !route.region_id) && !selectedRoute)"
        >
          Сохранить
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
import VueMask from 'v-mask'
Vue.use(VueMask)

import cloneDeep from 'lodash/cloneDeep'
import Datepicker from '@/components/Datepicker'
import { ListsService, RoutesService } from '@/common/api.service'

export default {
  props: {
    regions: {
      required: false,
      type: Array
    },
    routes: {
      required: false,
      type: Array
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    },
    regionId: {
      required: false,
      type: Number,
      default: null
    }
  },

  components: {
    Datepicker
  },

  data() {
    return {
      routeSaving: false,
      emptyState: {
        date: new Date().toISOString().substr(0, 10),
        verifier_id: null
      },
      route: null,
      verifiers: [],
      query: {
        district_id: [],
        route_id: this.id
      },
      dialog: false,
      selectedRoute: null
    }
  },

  computed: {
    allowedRoutes () {
      if (!this.routes) {
        return []
      }

      return this.routes.filter((route) => {
        return (parseInt(route.region_id) === parseInt(this.regionId)) && (!route.date || this.route.date === route.date)
      })
    },

    // Доступные поверители
    allowedVerifiers () {
      return this.verifiers.filter((v) => {
        if (parseInt(v.region_id) !== parseInt(this.regionId)) {
          return false
        }

        // Поверитель не имеет маршрутов в выбранную дату
        return v.routes_dates.indexOf(this.route.date) === -1
      }).map(item => ({ value: item.id, text: item.name }))
    }
  },

  methods: {
    onSubmit () {
      if (this.selectedRoute) {
        this.$emit('created', this.selectedRoute)
        this.dialog = false

        return
      }

      this.routeSaving = true

      // Add
      RoutesService
        .add(this.route)
        .then((response) => {
          this.resetState()

          this.$notification('Успешно сохранено')
          this.$emit('created', response.route)
          this.dialog = false
        })
        .catch(() => {
          this.$notification('Ошибка сохранения')
        })
        .finally(() => {
          this.routeSaving = false
        })
    },

    resetState () {
      this.route = cloneDeep(this.emptyState)
    }
  },

  beforeMount () {
    this.resetState()

    ListsService.get(['verifiers_with_dates'])
      .then(({ data }) => {
        this.verifiers = data.verifiers_with_dates
      })

    this.route.region_id = this.regionId
  },

  watch: {
    regionId: {
      handler (value) {
        this.route.region_id = value
      }
    },

    'route.date': {
      handler () {
        this.route.verifier_id = null
        this.selectedRoute = null
      }
    }
  }
}
</script>
