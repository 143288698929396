<template>
  <div>
    <Heading :title="(id ? 'Редактирование' : 'Добавление') + ' поверки'" />

    <VerificationForm
      :id="id"
    />
  </div>
</template>

<script>
import VerificationForm from "@/components/Verifications/Form"

export default {
  components: {
    VerificationForm
  },

  props: {
    id: {
      default: false
    }
  }
}
</script>
