<template>
  <div class="mt-4">
    <h2
      v-if="title"
      class="mb-4"
    >
      {{ title }}
    </h2>

    <v-combobox
      label="Марка"
      v-model="meter.type"
      :items="meter_brands.filter(m => m.type_id === 2).map(brand => brand.name)"
    />

    <v-select
      label="Типоразмер"
      v-model="meter.meter_id"
      :items="meters.filter(m => m.type_id === 2)"
      item-value="id"
      item-text="name"
    />

    <v-text-field
      type="number"
      label="Год выпуска"
      v-model="meter.issue_date"
    />

    <v-text-field
      label="Заводской номер"
      v-model="meter.factory_number"
    />

    <v-text-field
      type="number"
      label="Показания счётчика"
      v-model="meter.value"
    />

    <v-text-field
      type="number"
      label="Регистрационный номер"
      v-model="meter.reg_number"
    />

    <v-select
      :items="water_types.map(item => ({ value: item.id, text: item.name }))"
      label="Вода"
      v-model="meter.water_type_id"
    />
  </div>
</template>

<script>
import { ListsService } from '@/common/api.service'

export default {
  props: {
    value: {
      type: Object
    },
    title: {
      type: String
    }
  },

  data () {
    return {
      water_types: [],
      meters: [],
      meter_brands: []
    }
  },

  watch: {
    meter: {
      deep: true,

      handler (value) {
        this.$emit('input', value)
      }
    }
  },

  created () {
    ListsService
        .get(['water_types', 'meters', 'meter_brands'])
        .then(({ data }) => {
          this.water_types = data.water_types
          this.meters = data.meters
          this.meter_brands = data.meter_brands
        })
  },

  computed: {
    meter: {
      get () {
        return this.value || {}
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  }
}
</script>
